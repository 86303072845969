import React from 'react'
import classNames from 'classnames'

const HistoryItem = ({icon, year}) => {

    const itemClass = classNames({
        'history-icon': true,
        'flex flex-col h-full lg:p-0 lg:items-center lg:justify-center': true,
        [`${icon}`]:true
    });
    return (
        <div className={ itemClass }>
            <span className='text-white text-1xl font-bold'> { year } </span>
        </div>
    )
}
export default HistoryItem;