const jobsReducer = (jobs, action) => {
  switch (action.type) {
    case `FETCH_JOBS`:
      localStorage.removeItem("jobs");
      localStorage.setItem("jobs", JSON.stringify([ ...action.payload ]));
      return ([ ...action.payload ]);
    default:
      return jobs
  }
}

export default jobsReducer;
