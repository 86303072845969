import React from 'react'
import classNames from 'classnames'

const SlideBox = (props) => {
    
    const frontClassNames = classNames({
        'card-slide-content': true,
        [`${props.frontBgClass}`]:true,
        'bordered': props.bordered ? true : false
    });
    const backClassNames = classNames({
        'slide-back-overlay flex flex-col items-center justify-center': true,
        [`${props.frontBgClass}`]: true
    });

    return (
        <div className={frontClassNames}>
            <div className='slide-front'>
                <img className='card-icon ' alt='Benefic' src={props.icon} />
                <p className='card-title mx-0 text-center'> { props.title } </p>
            </div>
            <div className={backClassNames}>
                <img className='card-icon' alt='Benefic Text' src={props.icon} />
                { props.children }
            </div>
        </div>
    )
}
export default SlideBox;