import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

export const PageView = (path) => {
    ReactGA.pageview(path);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 * @param {integer} value 
 */

export const Event = ({category, action, value, label}) => {
    ReactGA.event({
      category: category,
      action: action,
      value: value,
      label: label
    });
  };