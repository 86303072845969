import React from 'react';
import { Router, LocationProvider } from '@reach/router';
import { history } from './routerHistory';

import '././tailwind.css';
import "./css/global.css"
import "./css/style.css"
import "./css/style_mobile.css"
import "./App.css"

import Provider from "./providers/Provider";
import NotFound from './pages/404';
import {initGA, PageView} from './analytics/ga';

import IndexPage from './pages/index';
import Ofertas  from './pages/ofertas';
import Oferta  from './pages/oferta';

history.listen(({location}) => {
  Promise.all([
    initGA()
  ]).then(
    PageView(location.pathname),
  );
});


const App = () => {
  return (
    <Provider>
      <LocationProvider history={ history }>
        <Router>
          <IndexPage path="/" component={IndexPage} />
          <NotFound default  />
        </Router>
      </LocationProvider>
    </Provider>
  );
}

export default App;
